<template>
  <div class="pdf-viewer-wrapper" v-if="isShow">
    <el-dialog
      :title="title"
      :custom-class="customClass"
      :width="dialogWidth"
      :visible.sync="isShow"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      :before-close="cancel"
      :top="top + '%'"
      :fullscreen="fullScreen"
      :append-to-body="appendToBody"
    >
      <iframe :src="viewerUrl" width="100%" height="490px"> </iframe>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click="cancel">
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
        <el-button size="mini" type="primary" @click="saveToAppendix">
          <!-- 保存到文件 -->
          {{ $t("vue_label_file_save_to_file") }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
/**
 * @description pdf预览
 * 功能：
 * 1、pdf预览
 * 逻辑：
 * 1、根据传入的pdf路径，对文件进行预览
 */
export default {
  name: "PdfViewerDialog",
  props: {
    /**
     * 预览弹窗距窗口上方距离
     */
    top: {
      type: Number,
      default: 3,
    },
    /**
     * 弹窗标题
     */
    title: {
      type: String,
      default() {
        return this.$i18n.t("vue_label_file_no_title");
      },
    },
    /**
     * 弹窗自定义样式类名
     */
    customClass: {
      type: String,
      default: "pdf-viewer-dialog-wrapper",
    },
    /**
     * 弹窗宽度
     */
    dialogWidth: {
      type: String,
      default: "80%",
    },
    /**
     * 控制弹窗显隐
     */
    isShow: {
      type: Boolean,
      default: false,
    },
    /**
     * 控制弹窗是否全屏
     */
    fullScreen: {
      type: Boolean,
      default: true,
    },
    /**
     * 预览文件路径
     */
    pdfUrl: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      appendToBody: true, // dialog自身是否插至body元素上
    };
  },
  methods: {
    /**
     * 关闭弹窗,取消预览
     */
    cancel() {
      this.$emit("cancel");
    },
    /**
     * 保存到文件
     */
    saveToAppendix() {
      this.$emit("saveToAppendix");
    },
  },
  computed: {
    /**
     * 文件路径
     */
    viewerUrl() {
      let url = this.pdfUrl;
      return this.$baseConfig.baseURL.slice(0, str.length - 2) + url;
    },
  },
};
</script>
